import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Image, Alert } from "react-bootstrap";
import style from "./payment.module.scss";
import Payments from "../../../service/common/PaymentApi";
import { useSelector } from "react-redux"
import RegisterService from "../../../service/Auth/Register";
function BankTransfer(props) {
  console.log('banktransfer', props)
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new Payments();
  const Regservice=new RegisterService();
  const { t } = useTranslation();
  const [state, setState] = useState({
    selectedFile: null,
    selectedFile2: null,
    url: "",
    url1: '',
    accoundDetails: "",
    enableFinish: false,
    showAlert: false,
    alertType: "",
    AlertMessage: "",
    imageUploaded: false,
    idproofuploaded:false,
    idproofcount:0,
    filename:'',
    bankreceipt:'',
  });
  useEffect(() => {
    const accoundService = new Payments();
    accoundService.accoundDetails().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          accoundDetails: res.data.account_info,
        }));
      }
    });
  }, []);

  //file change event
  const onFileChange = (event) => {
    // Update the state
    if (event.target.files[0]) {
      setState((prev) => ({
        ...prev,
        selectedFile: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
      }));
    }
  };
  // const onIdproofFileChange = (event) => {
  //   // Update the state
  //   if (event.target.files[0]) {
  //     setState((prev) => ({
  //       ...prev,
  //       selectedFile2: event.target.files[0],
  //       url1: URL.createObjectURL(event.target.files[0]),
  //     }));
  //   }
  // };
  //upload image
  const uploadImgae = () => {
    const formData = new FormData();
    // formData.append('register',state.bankreceipt)
    formData.append("file", state.selectedFile);
    formData.append("user_name", props.userName ?? "");
    formData.append("type", props.type ?? "subscription_renewal");
    service.upload_reciept(formData).then((res) => {
      if (res.status) {
        console.log('file',res.data.file_name)
        setState((prev) => ({
          ...prev,
          enableFinish: true,
          showAlert: true,
          alertType: "success",
          idproofcount:state.idproofcount+1,
          AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
          imageUploaded: true,
          bankreceipt:res.data.file_name
        }));
      } else {
        setState((prev) => ({
          ...prev,
          enableFinish: false,
          showAlert: true,
          alertType: "danger",
          AlertMessage: t("Common.error_file_upload"),
        }));
      }
    });
  };
 
  const uploadIdproof = () => {
    const formData = new FormData();
    formData.append("file", state.selectedFile2);
    formData.append("user_name", props.userName ?? "");
    // formData.append("type", props.type ?? "subscription_renewal");
    Regservice.ImageuploadSubmit(formData).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          enableFinish: true,
          showAlert: true,
          alertType: "success",
          filename:res.data.file_name,
          idproofcount:state.idproofcount+1,
          AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
          // imageUploaded: true,
          idproofuploaded:true
        }));
      } else {
        setState((prev) => ({
          ...prev,
          enableFinish: false,
          showAlert: true,
          alertType: "danger",
          AlertMessage: t("Common.error_file_upload"),
        }));
      }
    });
  };
  //remove image
  const removeImage = () => {
    setState((prev) => ({
      ...prev,
      selectedFile: "",
      url: "",
      enableFinish: false,
      showAlert: false,
      
    }));
    if (state.imageUploaded) {
      const username = {
        user_name: props.userName,
      };
      service.removeImage(username).then((res) => { 
        if(res.status){
          setState((prev)=>({
            ...prev,
            idproofcount:state.idproofcount-1,
           imageUploaded:false
          }))
        }
       });
    }
     
    
  };
  // const removeImage1 = () => {
  //   setState((prev) => ({
  //     ...prev,
  //     selectedFile1: "",
  //     url1: "",
  //     enableFinish: false,
  //     showAlert: false,
     
  //   }));
  //   if (state.imageUploaded) {
  //     // const username = {
  //     //   user_name: props.userName,
  //     // };
  //     const formdata=new FormData();
  //     formdata.append('file_name',state.filename)
  //     Regservice.removeImage(formdata).then((res) => {
  //       console.log('username',formdata)
  //       if(res.status){
  //         setState((prev)=>({
  //           ...prev,
  //           idproofcount:state.idproofcount-1,
  //           idproofuploaded:false,
  //         }))
  //       }
  //      });
  //   }
  // };
  // console.log('uidprrof',state.idproofcount)
  const uploadIdImgaeHandler1 = () => {
    props.uploadIdproof()
    document.getElementById('btn1').hidden = true
  }
  const uploadImgaeHandler2 = () => {
    uploadImgae()
    document.getElementById('btn2').hidden = true
  }
  const removeIdImageHandler1 = () => {
    props.removeImageHndler()
    document.getElementById('btn1').hidden = false
  }
  const removeImageHandler2 = () => {
    removeImage()
    document.getElementById('btn2').hidden = false
  }
  return (
    <div className={style.MianContent}>
      <Alert show={state.showAlert} variant={state.alertType} dismissible>
        {state.AlertMessage}
      </Alert>
      <Form.Group>
        <Form.Label>{t("Common.contactinfo")}</Form.Label>
      </Form.Group>
      <Form.Group>
        <Form.Label className={style.required}>
          {t("Common.selectproof")}
        </Form.Label>
        <Form.File onChange={e => props.changeHandler(e,'payment')} name='contract_proof' />
        <Form.Text className={style.textdanger}>
          {t("Common.allowTypes")}
        </Form.Text>
      </Form.Group>

      {props.url && (
        <Form.Group>
          <Image src={props.url} className={style.recieptPreview} />
          <Button className={style.RecieptRemoveButton} onClick={removeIdImageHandler1}>
            <i className="fa fa-times"></i>
            Remove
          </Button>
        </Form.Group>
      )}
      <Form.Group className={style.Buttons}>
        <Button
          id='btn1'
          onClick={uploadIdImgaeHandler1}
          disabled={!props.url}
          className={style.uploadButton}
        >
          <i className="fa fa-arrow-circle-o-up"></i>
          {t("Button.upload")}
        </Button>

      </Form.Group>
      <Form.Group>
        <Form.Label>{t("Common.bankDetails")}</Form.Label>
        <Form.Control
          as="textarea"
          inputClass={`${currentTheme == "theme-dark" &&
            `${style.dark_mode_textArea}`
            }`}
          rows={10}
          readOnly
          className={style.bankDetails}
          value={state.accoundDetails}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className={style.required}>
          {t("Common.selectRecipt")}
        </Form.Label>
        <Form.File onChange={onFileChange} name
        />
        <Form.Text className={style.textdanger}>
          {t("Common.allowTypes")}
        </Form.Text>
      </Form.Group>
      {state.url && (
        <Form.Group>
          <Image src={state.url} className={style.recieptPreview} />
          <Button className={style.RecieptRemoveButton} onClick={removeImageHandler2}>
            <i className="fa fa-times"></i>
            Remove
          </Button>
        </Form.Group>
      )}
      <Form.Group className={style.Buttons}>
        <Button
          id="btn2"
          onClick={uploadImgaeHandler2}
          disabled={!state.url}
          className={style.uploadButton}
        >
          <i className="fa fa-arrow-circle-o-up"></i>
          {t("Button.upload")}
        </Button>
        {
                            props.prevButtonClick&&
                                <Button onClick={props.prevButtonClick} className={style.PrevButton}>{t('profile.prev')}</Button>
                        }
        {
          (props.idproof>=1&&state.imageUploaded==true) &&
          <Button
            disabled={!state.enableFinish}
            type="submit"
            className={style.NextButton}
          >
            {t("Button.finish")}
          </Button>
        }

      </Form.Group>
    </div>
  );
}

export default BankTransfer;
