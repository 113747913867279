import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import API from "../../api/api";

export default class RegisterService {
  key = localStorage.getItem("apiKey");

  RegisterDetails = async () => {
    return API.API.get("api_register/register")
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  validateSponsorUserName = async (payload) => {
    return API.API.get("api_register/validate_username", payload).then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      }
    );
  };
  checklegAvailability = async (payload) => {
    return API.API.post("api_register/check_leg_availability", payload).then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      }
    );
  };

  checkUsename = async (username) => {
    return API.API.post("api_register/check_username", username)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  ImageuploadSubmit = async (payload) => {
    return API.API.post("/api_register/upload_id_image",payload)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };


  async removeImage(verificationid){
    const response = await fetch(BASE_URL+'/api_register/remove_verification_image', {     //url
        method: 'POST',                 //method
        headers : {                     //passing header 
            // 'Accept'        : 'application/json',
            // 'Content-Type'  : 'application/json',
            'api-key'       : this.key,
            'access-token'  : UserStore.key
        },
        body: verificationid
    })
    const legStatus = await response.json();
    return legStatus;
}
  regsiterSubmit = async (payload) => {
    return API.API.post("api_register/register_submit",payload)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  registerSummary = async (id) => {
    return API.API.get("api_register/registration_preview?username=" + id)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  checkPlacemntLegAvailability = async (username, position) => {
    return API.API.get(
      "api_register/check_leg_availability?placement_user_name=" +
        username +
        "&position=" +
        position
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  // //get the registration details
  // async RegisterDetails(){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'api_register/register', {     //url
  //             method: 'GET',                 //method
  //             headers : {                     //passing header
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  // //check the sponsor username validation
  // async validateSponsorUserName(sponsorData){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'api_register/validate_username', {     //url
  //             method: 'POST',                 //method
  //             headers : {                     //passing header
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             body: JSON.stringify(sponsorData)  //convert to json
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //check the leg availability
  // async checklegAvailability(legDetails){
  //     const response = await fetch(BASE_URL+'api_register/check_leg_availability', {     //url
  //         method: 'POST',                 //method
  //         headers : {                     //passing header
  //             'Accept'        : 'application/json',
  //             'Content-Type'  : 'application/json',
  //             'api-key'       : this.key,
  //             'access-token'  : UserStore.key
  //         },
  //         body: JSON.stringify(legDetails)  //convert to json
  //     })
  //     const legStatus = await response.json();
  //     return legStatus;
  // }

  //chekc the username
  // async checkUsename(username){
  //     const response = await fetch(BASE_URL+'api_register/check_username', {     //url
  //         method: 'POST',                 //method
  //         headers : {                     //passing header
  //             'Accept'        : 'application/json',
  //             'Content-Type'  : 'application/json',
  //             'api-key'       : this.key,
  //             'access-token'  : UserStore.key
  //         },
  //         body: JSON.stringify(username)  //convert to json
  //     })
  //     const legStatus = await response.json();
  //     return legStatus;
  // }

  //register submit
  // async regsiterSubmit(formData){
  //     const response = await fetch(BASE_URL+'api_register/register_submit', {     //url
  //         method: 'POST',                 //method
  //         headers : {                     //passing header
  //             'Accept'        : 'application/json',
  //             'Content-Type'  : 'application/json',
  //             'api-key'       : this.key,
  //             'access-token'  : UserStore.key
  //         },
  //         body: JSON.stringify(formData)  //convert to json
  //     })

  //     const legStatus = await response.json();
  //     return legStatus;
  // }

  // //register Summary
  // async registerSummary(id=''){
  //     const response = await fetch(BASE_URL+'api_register/registration_preview?username='+id, {     //url
  //         method: 'GET',                 //method
  //         headers : {                     //passing header
  //             // 'Accept'        : 'application/json',
  //             // 'Content-Type'  : 'application/json',
  //             'api-key'       : this.key,
  //             'access-token'  : UserStore.key
  //         }
  //     })
  //     const legStatus = await response.json();
  //     return legStatus;
  // }
  // async checkPlacemntLegAvailability(username,position){
  //     const response = await fetch(BASE_URL+'api_register/check_leg_availability?placement_user_name='+username+'&position='+position, {     //url
  //         method: 'GET',                 //method
  //         headers : {                     //passing header
  //             // 'Accept'        : 'application/json',
  //             // 'Content-Type'  : 'application/json',
  //             'api-key'       : this.key,
  //             'access-token'  : UserStore.key
  //         }
  //     })
  //     const result = await response.json();
  //     return result;
  // }
}
