import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import PageTitle from '../../../components/common/pageTitle';
import { withTranslation }  from 'react-i18next';
import {PreviewContent} from '../../../components/registration';
import { withRouter } from 'react-router-dom';
import RegisterService from '../../../../service/Auth/Register';
import companyName from '../../../../store/mobxStore/companyName';
import { Spinner } from 'react-bootstrap';
class Preview extends PureComponent {
    constructor(props) {
        console.log('oreview',props)
        super(props)

        this.state = {
            loading : true,
            data : {}
        }
        this.username = this.props.match.params;
        this.service = new RegisterService();
    }
    componentDidMount(){
        const username = this.props.match.params.id;
        this.service.registerSummary(username).then((res)=>{
            console.log('register',res)
            if(res.status){
                this.setState({
                    loading  :false,
                    data :res.data
                })
            }else{
                if(res.error.code === 1011){
                    this.props.history.push('/dashboard');
                }else if(res.error.code === 1037){
                    this.props.history.push('/dashboard');
                    alert(this.props.t('Common.Email_Verification_Required',{ id: this.username.id }));
                   }
                else{
                    this.props.history.push('/logout');
                }
            }
        })
    }

    render() {
        const { t } = this.props;
        const { location } = this.props;
        if(this.state.loading){
            return <Spinner/>
        }
        return (
           <div className={`h-100`}>
               <Helmet>
                    <title>{companyName.companyName} | {t('Sidemenu.letterPreview')}</title>
                </Helmet>
                <PageTitle 
                    title="letterPreview" 
                    buttonOn={false}
                />
                {
                    this.state &&
                    <PreviewContent 
                        alertmessage={location.data??''}
                        data={this.state}
                    />
                }
           </div> 
        )
    }
}

export default withTranslation()(withRouter(Preview))