import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import style from "./package.module.scss";
import { useTranslation } from "react-i18next";
import UpgradePackageList from "../package/UpgradePackageList";
import UpgradePackage from "../package/UpgradePackage";
import PaymentMethods from "../common/PaymentMethods";
import AlertBs from "../common/AlertBs";
import { useSelector } from "react-redux";

function UpgradeContent(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const currency = useSelector((state) => state.curr);
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_MainContainer}`
          : `${style.MainContainer}`
      }`}
    >
      <Row>
        <Col>
          <AlertBs {...props.alert} close={props.closeAlert} />
          <Card
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_ManiContent}`
                : `${style.ManiContent}`
            }`}
          >
            <Card.Body className={style.ContentBody}>
              <table>
                <tbody>
                  <tr>
                    <td>{t("Common.package")} &nbsp;&nbsp;</td>
                    <td>: &nbsp;&nbsp;</td>
                    <td>{props.data.product_name}</td>
                  </tr>

                  <tr>
                    <td>{t("Common.price")} </td>
                    <td>:</td>
                    <td>
                      {currency.currentCurr}{" "}
                      {(currency.value * props.data.price).toFixed(
                        currency.precision
                      )}{" "}
                    </td>
                  </tr>

                  <tr>
                    <td>{t("Common.PV")}</td>
                    <td>:</td>
                    <td>{props.data.pv}</td>
                  </tr>
                </tbody>
              </table>
              <UpgradePackageList
                upgrade_list={props.upgrade_list}
                selectPackage={props.selectPackage}
              />
              {props.selected_package && (
                <Row>
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? `${style.dark_mode_tabSection}`
                        : `${style.tabSection}`
                    }`}
                    id="upgrade_pack_div2"
                  >
                    <div
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_tabContainer}`
                          : `${style.tabContainer}`
                      }`}
                    >
                      <UpgradePackage
                        data={props.selected_package}
                        current_package_price={props.data.price}
                      />
                      {/* <Form> */}
                      <PaymentMethods
                        methods={props.paymentData}
                        selectedTab={0}
                        product={props.selected_package.product_id}
                        payment_type="package_upgrade"
                        paymentMethodChange={props.tabChange}
                        amount={parseInt(props.data.price).toFixed(2)}
                        {...props}
                        submit={props.onSumbit}
                      />
                      {/* </Form> */}
                    </div>
                  </div>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UpgradeContent;